import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"

const NotFoundPage = () => {
  return (
    <Layout 
      pageMeta={{
        title: "Not Found"
      }}
      > 
        <h1 class="text-2xl">Page not found</h1>
        <br />
        <Link to="/">Go home</Link>.
    </Layout>
  )
}

export default NotFoundPage
